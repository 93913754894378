import React, { useState } from "react"
import LayoutEng from "../../components/LayoutEng"
import SEO from "../../components/SEO"
import BlogFilterEng from "../../components/BlogFilterEng"

const Blog = () => {
   return (
    <LayoutEng>
      <SEO title="Blog" />
      <main className="page blog-view">
      <div className="Search">
      <h1 style={{ textAlign: `center` }}><strong className="pageNaslov apsihaBlog">Apsiha </strong> Blog</h1>
      <BlogFilterEng />
      </div>
      </main>
    </LayoutEng>
  )
}

export default Blog